* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  border: 0;
}



@media screen and (max-width: 1400px) {
  html {
      width: 100vw;
      overflow-x: hidden;
  }
  
  
  
  
  
  
}
