.navMenu {
    position: fixed;
    width: 18vw;
    height: 100vh;
    background-color: #C48BD9;  
    z-index: 2; 
}

menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50%;
}

.navLink {
    text-decoration: none;
}

.navItem {
    text-align: center;
    font-size: larger;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navLink, .navItem {
    width: 100%;
    height: 10vh;
    margin: auto;
}

.navLink:hover, .navItem:hover {
    background-color: white;
    color: #C48BD9;
}
@media screen and (min-width: 1400px) {
    #logoimg {
        width: 100%;
}
}
.menuIcon {
    display: none;
}
.hiddenMenu {
    display: none;
}


@media screen and (max-width: 1400px) {
    .navMenu {
        position: sticky;
        top: 0;
        width: 100vw;
        height: 15vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    }
    menu {
        display: none;
    }
    
    
    #logoimg {
        object-fit: contain;
        height: 15vh;
        padding: 0;
    }
    .menuIcon {
        color: white;
        margin-right: 5vw;
    }
    .menuIcon:hover {
        background-color: white;
        color: #C48BD9;
    }
    
}